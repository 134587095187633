<script setup>
import MastheadPost from "~/components/MastheadPost/MastheadPost.vue";

useHead({
  title:
    "Leelee Loves - Pregnancy, Parenting, Motherhood, Lifestyle, Reviews, Days Out",
  meta: [
    {
      hid: "description",
      name: "description",
      content: "Pregnancy, Parenting, Motherhood, Lifestyle, Reviews, Days Out",
    },
  ],
  script: [
    {
      async: true,
      src: "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9331297174847975",
      crossOrigin: "anonymous",
    },
    {
      async: true,
      src: "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9331297174847975",
      crossOrigin: "anonymous",
    },
  ],
});
</script>

<template>
  <div>
    <MastheadPost category="Pregnancy" title="Hello World" />
    <CategoryBlock category="Pregnancy 🤰" slug="pregnancy" />
    <CategoryBlock category="Parenting 👨‍👩‍👧" slug="parenting" />
    <CategoryBlock category="Motherhood 👩‍🍼" slug="motherhood" />
    <CategoryBlock category="Reviews ✨" slug="reviews" />
    <CategoryBlock category="Lifestyle ✌️" slug="lifestyle" />
    <CategoryBlock category="Days Out 😎" slug="days-out" />
  </div>
</template>
